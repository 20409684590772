import { Constantes } from '../../common/constantes';

export default {
  title: 'squadra',

  backgroundFront: `${Constantes.HOST_WINGS}/img/frenteprocesso.png`,
  backgroundBack: `${Constantes.HOST_WINGS}/img/fundomao.png`,

  backgroundFrontStyle: {
    width: '',
    margin: '',
    display: '',
    position: '',
    backgroundSize: '',
    height: '',
    backgroundRepeat: '',
  },

  colors: {
    primary: '#273142',
    text: '#fff',
  },

  logoHeader: `${Constantes.HOST_WINGS}/img/logo1.png`,
  logoFooter: '',
};
