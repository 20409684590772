import React, { useState } from 'react';
import { FaUserCircle, FaCaretDown } from 'react-icons/fa';
import { UserData } from '../../pages/Dashboard';
import { UserBox } from './userBox';
import { Container, Footer, Content, UserDropdownContainer } from './styles';

export function UserDropdown(user: UserData): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);

  function handleLogout() {
    const orgUnit = user.org_unit;
    window.location.href = `/${orgUnit}/sites/auth/Logout`;
  }

  return (
    <>
      <UserDropdownContainer>
        <button type="button" onClick={() => setIsOpen(!isOpen)}>
          <FaUserCircle size={30} />

          <span>{user.given_name}</span>

          <FaCaretDown />
        </button>
      </UserDropdownContainer>

      {isOpen && (
        <Container>
          <Content>
            <UserBox currentUser={user} />

            <span>{user.given_name}</span>
            <span style={{ paddingBottom: '5px' }}>{user.org_unit}</span>
          </Content>

          <Footer>
            <button type="button" onClick={handleLogout}>
              Sair
            </button>
          </Footer>
        </Container>
      )}
    </>
  );
}
