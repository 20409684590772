import { useContext, useEffect, useState } from 'react';
import { FaPlayCircle, FaChartBar, FaCogs, FaLock } from 'react-icons/fa';
import { ThemeContext } from 'styled-components';
import { useLocation } from 'react-router-dom';

import { parseJwt } from '../../utils/parseJwt';

import { Link } from '../../components/Link';
import { UserDropdown } from '../../components/UserDropdown';

import { Constantes } from '../../common/constantes';

import {
  Background,
  Container,
  Content,
  ContianerActions,
  ContainerLink,
  BackgroundSobreposta,
  ContainerFooter,
  ContainerFooterLogo,
  Header,
} from './styles';

export interface UserData {
  unique_name: string;
  sub: string;
  given_name: string;
  org_unit: string;
}

export function Dashboard(): JSX.Element {
  const [user, setUser] = useState<UserData>();

  const { logoHeader, backgroundFrontStyle, logoFooter } =
    useContext(ThemeContext);

  const { pathname } = useLocation();

  const organizationId = pathname;

  if(!organizationId || organizationId === "/"){
    let lastOrg = window.localStorage.getItem("lastOrgId")??"Squadra";
    window.location.href =`${Constantes.HOST_WINGS}/${lastOrg}`;
  }
  else if(user && user.org_unit && organizationId.replace("/","").toLowerCase() != user.org_unit.toLowerCase()){
    window.location.href = `${Constantes.HOST_WINGS}/${user.org_unit}`;
  }
  else
    window.localStorage.setItem("lastOrgId",organizationId.replace("/",""));

  useEffect(() => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; W4BToken`);
    let token;

    if (parts.length === 2) {
      token = parts.pop()?.split(';').shift();
    }

    if (!token || token.length === 0) return;

    const jsonToken = parseJwt(token);

    setUser(JSON.parse(jsonToken));
  }, []);

  return (
    <Container>
      <Background>
        <BackgroundSobreposta
          style={{
            width: backgroundFrontStyle.width,
            margin: backgroundFrontStyle.margin,
            display: backgroundFrontStyle.display,
            position: 'absolute',
            backgroundSize: backgroundFrontStyle.backgroundSize,
            height: backgroundFrontStyle.height,
            backgroundRepeat:'no-repeat'
          }}
        />
      </Background>

      <Content>
        <Header>
          <a
            className="linkIdentity"
            href={`${Constantes.HOST_WINGS}${organizationId}/sites/auth/usersmanager`}
          >
            <FaLock size={25} />
          </a>

          {user && (
            <UserDropdown
              unique_name={user.unique_name}
              sub={user.sub}
              given_name={user.given_name}
              org_unit={user.org_unit}
            />
          )}
        </Header>

        <ContianerActions>
          {logoHeader && <img src={logoHeader} alt="Logo wings" />}

          <ContainerLink>
            <Link
              href={`${Constantes.HOST_WINGS}${organizationId}/sites/operacional`}
              icon={FaPlayCircle}
            >
              Módulo Operacional
            </Link>

            <Link
              href={`${Constantes.HOST_WINGS}${organizationId}/sites/portal`}
              icon={FaChartBar}
            >
              Portal de Gestão
            </Link>

            <Link
              href={`${Constantes.HOST_WINGS}${organizationId}/sites/configuracao`}
              icon={FaCogs}
            >
              Módulo de Configuração
            </Link>
          </ContainerLink>
        </ContianerActions>

        <ContainerFooter>
          {logoFooter && (
            <ContainerFooterLogo>
              <img src={logoFooter} alt="Logo wings" />
            </ContainerFooterLogo>
          )}
        </ContainerFooter>
      </Content>
    </Container>
  );
}
