import styled from 'styled-components';

export const Container = styled.a`
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.colors.primary};
  border-radius: 10px;
  border: 0;
  padding: 7%;
  color: ${props => props.theme.colors.text};
  width: 100%;
  max-width: 750px;
  font-weight: 500;
  font-size: 150%;
  box-shadow: 0 10px 10px 0 #666;
  text-decoration: none;

  transition: filter 0.2s;

  > svg {
    margin-right: 10px;
  }

  &:hover {
    filter: brightness(0.8);
  }
`;
