import React, { LinkHTMLAttributes } from 'react';
import { IconBaseProps } from 'react-icons';

import { Container } from './styles';

interface LinkProps extends LinkHTMLAttributes<HTMLLinkElement> {
  icon?: React.ComponentType<IconBaseProps>;
}

export function Link({ children, icon: Icon, href }: LinkProps): JSX.Element {
  return (
    <Container href={href}>
      {Icon && <Icon size={30} />}
      {children}
    </Container>
  );
}
