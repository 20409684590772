import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  width: 100vw;

  display: flex;
  align-items: stretch;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px;

  > a.linkIdentity {
    color: ${props => props.theme.colors.primary};
  }
`;

export const Background = styled.div`
  width: 100%;
  background: url(${props => props.theme.backgroundBack}) 0% 0% no-repeat
    padding-box;
  background-size: cover;
  background-position: center;
  position: relative;

  @media (max-width: 700px) {
    display: none;
  }
`;

export const BackgroundSobreposta = styled.div`
  background-image: url(${props => props.theme.backgroundFront});
  background-size: 100%;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @media (max-width: 700px) {
    display: none;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  max-width: 980px;
  width: 100%;
  height: 100vh;
`;

export const ContianerActions = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 80px;
  gap: 10%;
  margin-top: 2%;

  > img {
    max-width: 280px;
  }
`;

export const ContainerLink = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10%;
`;

export const ContainerFooter = styled.div`
  width: 100%;
`;

export const ContainerFooterLogo = styled.div`
  position: unset;
  bottom: 0px;
  right: 0px;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  margin-top: auto;

  > img {
    max-width: 170px;
  }
`;
