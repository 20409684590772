import React, { useState } from 'react';
import { FaUserCircle, FaCaretDown } from 'react-icons/fa';
import { UserData } from '../../pages/Dashboard';
import style from './userBox.module.css';

export function UserBox(currentUser: any) {
  function getInitials(name: string): string {
    const names = name.trim().split(' ');

    if (names.length === 1) {
      return names[0].substring(0, 3).toUpperCase();
    }

    const firstNameInitial = names[0].charAt(0).toUpperCase();
    const lastNameInitial =
      names.length === 2
        ? names[1].charAt(0).toUpperCase()
        : names[names.length - 1].charAt(0).toUpperCase();

    return firstNameInitial + lastNameInitial;
  }
  return (
    <div className={style['box-circle']}>
      <div className={style['text-user']}>
        {getInitials(currentUser.currentUser.given_name)}
      </div>
      <div>{currentUser.given_name}</div>
    </div>
  );
}
