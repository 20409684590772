import styled from 'styled-components';

export const UserDropdownContainer = styled.div`
  > button {
    display: flex;
    align-items: center;
    gap: 10px;

    padding: 4px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    margin: 4px 10px;

    svg {
      color: #666;
    }

    > span {
      color: #666;
    }
  }
`;

export const Container = styled.div`
  position: absolute;
  top: 7%;
  right: 0;
  margin-right: 10px;
  width: 328px;
  height: 200px;
  background-color: #2a5c78;
  color: ${props => props.theme.colors.text};
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-family: Montserrat, Medium;
  > button {
    border: none;
    background: transparent;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  > svg {
    color: #000;
    width: 100px;
    height: 100px;
  }

  > span,
  strong {
    text-transform: capitalize;
  }

  > strong {
    font-size: 18px;
    font-weight: 700;
  }

  > span {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
  }
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 10px;
  align-items: center;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid #e7e7e7;

  > button {
    padding: 4px;
    font-weight: 400;
    width: 100%;
    border-radius: 5px;
    transition: 0.3s ease-in-out;
    margin-bottom: 4px;
    font-size: 1rem;
    color: #212529;
    font-weight: 400;
    list-style: none;
    line-height: 1.5;
    box-shadow: none;
    border-width: 1px;
    padding: 0.375rem 0.75rem;
    background-color: #f4f4f4;
    text-align: center;
    vertical-align: middle;
    text-decoration: none;
    border: 1px solid;
    border-color: #ddd;
  }
  button:active {
    box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
  }

  button:hover {
    background-color: #e7e7e7;
  }
`;
