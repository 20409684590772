import { Switch, Route } from 'react-router-dom';

import { Dashboard } from '../pages/Dashboard';

export const Routes = (): JSX.Element => {
  return (
    <Switch>
      <Route path="/health">
        <h3>The App is Healthy</h3>
      </Route>

      <Route path="/" component={Dashboard} />
    </Switch>
  );
};
